import {message} from 'antd';

export const showSuccessFlashMessage = (msg = '') => {
  message.success({content: msg}).then();
};

export const showErrorFlashMessage = (msg = '') => {
  message.error({content: msg}).then();
};

export const showWarningFlashMessage = (msg = '') => {
  message.warning({content: msg}).then();
};
